import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Responses } from './../common/enum';

@Injectable({
  providedIn: 'root',
})
export class LoginManagementApi {
  constructor(private apollo: Apollo) {}

  verifyAccount(code: string): Observable<Responses> {
    const VERIFY_CODE = gql`
      mutation {
        verifyCode(input: { code: "${code}" }) {
          response
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation: VERIFY_CODE,
      })
      .pipe(
        map(({ data }) => {
          return data['verifyCode']['response'] as Responses;
        })
      );
  }

  resetPassword(code: string, password: string): Observable<Responses> {
    const VERIFY_CODE = gql`
      mutation {
        resetPassword(input: { code: "${code}", password: "${password}" }) {
          response
        }
      }
    `;
    return this.apollo
      .mutate({
        mutation: VERIFY_CODE,
      })
      .pipe(
        map(({ data }) => {
          return data['resetPassword']['response'] as Responses;
        })
      );
  }
}
