<div class="container-modal" [ngClass]="{'destructive': confirmData?.type == ConfirmationType.DestructiveAction}"
    *ngIf="isVisible">
    <div class="popup">
        <div class="indicator"></div>
        <div *ngIf="confirmData?.headerTitle" class="title">
            {{confirmData?.headerTitle}}
        </div>
        <div *ngIf="confirmData?.message" class="message">
            {{confirmData?.message}}
        </div>
        <div class="actions">
            <div class="secondary-action button" (click)="onActionButtonClicked(false)">
                {{confirmData?.cancelActionText}}
            </div>
            <div class="primary-action button" (click)="onActionButtonClicked(true)">
                {{confirmData?.mainActionText}}
            </div>
        </div>
    </div>
</div>
