import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeepLinksType } from '../core/common/enum';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if (this.router.url.indexOf('/page/register-validate-code') > -1 || this.router.url.indexOf('/page/reset-password') > -1) {
    } else {
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
        const type = params['type'] as DeepLinksType;
        const code = params['code'];
        if (type != null) {
          if (type == DeepLinksType.Register) {
            this.router.navigate(['/page/register-validate-code', code]);
          } else if (type == DeepLinksType.ForgetPassword) {
            this.router.navigate(['/page/reset-password', code]);
          }
        } else {
          this.router.navigate(['/404']);
        }
      });
    }
  }
}
