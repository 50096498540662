import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { LanguageEnum } from './../common/enum';
import { Language } from './../model/';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  @Output() languageChanged = new EventEmitter<Language>();

  // public fields
  currentLanguage$: BehaviorSubject<Language> = new BehaviorSubject(new Language());
  supportedLanguage$: BehaviorSubject<Language[]> = new BehaviorSubject([]);

  // private fields
  private supportedLanguagesList = [
    { name: 'العربية', isoCode: LanguageEnum.Arabic } as Language,
    { name: 'English', isoCode: LanguageEnum.English } as Language,
  ];

  getSupportedLanguagesList() {
    return this.supportedLanguagesList;
  }

  constructor(
    private translate: TranslateService,
  ) {
    this.setupAppLanguageList();
    this.initialState();
    this.supportedLanguage$.subscribe((languages) => {
      if (!languages.length) { return; }
      this.initialState();
    });
  }

  changeLanguage(languageIsoCode: string) {
    if (this.currentLanguage$.value.isoCode === languageIsoCode) {
      return;
    }
    const language = this.supportedLanguagesList.find((lang) => languageIsoCode === lang.isoCode);
    if (language) {
      this.saveCurrentLanguage(language);
      this.languageChanged.emit(language);
    }
  }

  checkLanguageDirection(languageIsoCode: string): string {
    if (languageIsoCode === 'ar') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

  setOrganizationSupportedLanguage(supportedLanguages: Language[]) {
    this.supportedLanguagesList = [];
    this.supportedLanguagesList = supportedLanguages;
    this.supportedLanguage$.next(this.supportedLanguagesList);
  }

  private saveCurrentLanguage(language: Language) {
    this.currentLanguage$.next(language);
    localStorage.setItem("Lang", language.isoCode);
    this.translate.use(this.currentLanguage$.value.isoCode);
    this.setWebSiteDirection();
  }

  private setupAppLanguageList() {
    const languagesArray: string[] = [];
    this.supportedLanguagesList.forEach((lang) => {
      languagesArray.push(lang.isoCode);
    });
    this.translate.addLangs(languagesArray);
  }

  private setWebSiteDirection() {
    const direction = this.checkLanguageDirection(this.currentLanguage$.value.isoCode);
    document.body.dir = direction;
    document.body.parentElement.dir = direction;
    document.body.firstElementChild.setAttribute('dir', direction);
  }

  private initialState() {
    const appLangIsoCode = localStorage.getItem("Lang");
    let currentLanguage = new Language();
    if (appLangIsoCode) {
      currentLanguage = this.supportedLanguagesList.find((lang) => lang.isoCode === appLangIsoCode);
    } else {
      currentLanguage = this.supportedLanguagesList[0];
      this.saveCurrentLanguage(currentLanguage);
    }
    this.currentLanguage$.next(currentLanguage);
    this.translate.use(this.currentLanguage$.value.isoCode);
    this.translate.setDefaultLang(this.currentLanguage$.value.isoCode);
    this.setWebSiteDirection();
  }
}
