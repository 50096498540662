import { take } from 'rxjs/operators';
import { LanguageEnum } from './../core/common/enum';
import { LanguageService } from './../core/helper/language.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'AgriCBS';

  constructor(public languageService: LanguageService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const language = params['lang'];
      if (language) {
        this.languageService.changeLanguage(language);
      }
    });
  }

  ngOnInit(): void {}

  changeLanguage() {
    if (this.languageService.currentLanguage$.value.isoCode === LanguageEnum.English) {
      this.languageService.changeLanguage(LanguageEnum.Arabic);
    } else {
      this.languageService.changeLanguage(LanguageEnum.English);
    }
  }
}
