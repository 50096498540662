export enum LoadingStatus {
  Hidden = 'Hidden',
  Visible = 'Visible',
  VisibleTransparent = 'VisibleTransparent',
}

export enum SectionStateStatus {
  Loading = 'Loading',
  LoadingTransparent = 'LoadingTransparent',
  Ready = 'Ready',
  Disconnected = 'Disconnected',
  Error = 'Error',
  Empty = 'Empty',
  EmptyFilterResult = 'EmptyFilterResult'
}

export enum ScreenType {
  phone = 'phone',
  tablet = 'tablet',
  desktop = 'desktop',
}

export enum DrawerPosition {
  Start = 'start',
  End = 'end',
}

export enum DrawerMode {
  Side = 'side',
  Over = 'over',
  Push = 'push',
}

export enum DrawerType {
  MobileMenu = 'mobileMenu',
  Notifications = 'notifications'
}

export enum UploadingStatus {
  Done = 'done',
  Uploading = 'uploading',
  Error = 'error'
}

export enum VerifyAccountPageType {
  None = 'None',
  NewAccount = 'NewAccount',
  ExistingAccount = 'ExistingAccount'
}
