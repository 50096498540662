import { WelcomePageComponent } from './page/welcome-page/welcome-page.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { NotFoundPageComponent } from './page/not-found-page/not-found-page.component';

@NgModule({
  declarations: [NotFoundPageComponent, WelcomePageComponent],
  imports: [CommonModule, SharedModule],
})
export class StaticModule {}
