import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoginManagementApi } from './../../../../core/api';
import { Responses } from './../../../../core/common/enum';

@Component({
  selector: 'app-register-validate-code-page',
  templateUrl: './register-validate-code-page.component.html',
  styleUrls: ['./register-validate-code-page.component.scss'],
})
export class RegisterValidateCodePageComponent implements OnInit {
  private destroy$ = new Subject();

  isLoading = true;
  result: Responses = null;

  // Enum
  Responses = Responses;

  constructor(private loginManagementApi: LoginManagementApi, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('code')) {
        this.isLoading = true;
        this.loginManagementApi
          .verifyAccount(params.get('code'))
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (response: Responses) => {
              this.result = response;
              this.isLoading = false;
            },
            (error) => {
              this.result = Responses.SERVER_ERROR;
              this.isLoading = false;
            }
          );
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
