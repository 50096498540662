import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LoginManagementApi } from './../../../../core/api/login-management.api';
import { Responses } from './../../../../core/common/enum';
import { MatchPassword, PasswordValidator } from './../../../shared/custom-validators/form-validators';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent implements OnInit {
  private destroy$ = new Subject();
  private code: string;

  isLoading = false;
  result: Responses = null;
  hidePassword = true;
  form = this.formBuilder.group(
    {
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), PasswordValidator]],
      confirmPassword: ['', Validators.required],
    },
    { validator: MatchPassword }
  );

  // Enum
  Responses = Responses;

  constructor(
    private loginManagementApi: LoginManagementApi,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('code')) {
        this.code = params.get('code');
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  resetPassword() {
    this.isLoading = true;
    this.loginManagementApi
      .resetPassword(this.code, '')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: Responses) => {
          this.result = response;
          this.isLoading = false;
        },
        (error) => {
          this.result = Responses.SERVER_ERROR;
          this.isLoading = false;
        }
      );
  }
}
