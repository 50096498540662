<div class="box-grid">
  <!-- <div class="box box1"></div>
  <div class="box box2"></div>
  <div class="box box3"></div>
  <div class="box box4"></div>
  <div class="box box5"></div>
  <div class="box box6"></div>
  <div class="box box7"></div>
  <div class="box box8"></div>
  <div class="box box9"></div> -->
  <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>