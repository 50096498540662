<div id="page-wrap">
  <!-- ==========================================================================================================
													   HERO
		 ========================================================================================================== -->

  <div id="fh5co-hero-wrapper">
    <nav class="container navbar navbar-expand-lg main-navbar-nav navbar-light">
      <div style="width: 100%" class="flex row justify-content-between align-items-center">
        <a class="navbar-brand" [routerLink]="['/']">{{'General.Title' | translate}}</a>
        <button (click)="changeLanguage()" class="language-button" type="button">
          <span>{{languageService.currentLanguage$.value.isoCode.toLocaleUpperCase()}}</span>
        </button>
      </div>
      <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button> -->
      <!--

			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav nav-items-center ml-auto mr-auto">
					<li class="nav-item active">
						<a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#" onclick="$('#fh5co-features').goTo();return false;">Features</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#" onclick="$('#fh5co-reviews').goTo();return false;">Reviews</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#"  onclick="$('#fh5co-download').goTo();return false;">Download</a>
					</li>
				</ul>
				<div class="social-icons-header">
					<a href="https://www.facebook.com/fh5co"><i class="fab fa-facebook-f"></i></a>
					<a href="https://freehtml5.co"><i class="fab fa-instagram"></i></a>
					<a href="https://www.twitter.com/fh5co"><i class="fab fa-twitter"></i></a>
				</div>
			</div> -->
    </nav>

    <div class="pages-container">
      <router-outlet></router-outlet>
      <app-shared></app-shared>
    </div>

    <!-- <div class="container fh5co-hero-inner">
			<h1 class="animated fadeIn wow" data-wow-delay="0.4s">A Free HTML5 App Template Built with Bootstrap 4</h1>
			<p class="animated fadeIn wow" data-wow-delay="0.67s">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Et voluptates, aliquid soluta distinctio dolorum tenetur. </p>
			<button class="btn btn-md download-btn-first wow fadeInLeft animated" data-wow-delay="0.85s" onclick="$('#fh5co-download').goTo();return false;">Download</button>
			<button class="btn btn-md features-btn-first animated fadeInLeft wow" data-wow-delay="0.95s" onclick="$('#fh5co-features').goTo();return false;">Features</button>
			<img class="fh5co-hero-smartphone animated fadeInRight wow" data-wow-delay="1s" src="img/phone-image.png" alt="Smartphone">
		</div> -->
  </div>
  <!-- first section wrapper -->

  <!-- ==========================================================================================================
													 ADVANTAGES
		 ========================================================================================================== -->

  <!-- <div class="fh5co-advantages-outer">
		<div class="container">
			<h1 class="second-title"><span class="span-perfect">Perfect</span> <span class="span-features">Features</span></h1>
			<small>Only necessary</small>

			<div class="row fh5co-advantages-grid-columns wow animated fadeIn" data-wow-delay="0.36s">

				<div class="col-sm-4">
					<img class="grid-image" src="img/icon-1.png" alt="Icon-1">
					<h1 class="grid-title">Usability</h1>
					<p class="grid-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem cupiditate.</p>
				</div>

				<div class="col-sm-4">
					<img class="grid-image" src="img/icon-2.png" alt="Icon-2">
					<h1 class="grid-title">Parallax Effect</h1>
					<p class="grid-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem cupiditate.</p>
				</div>

				<div class="col-sm-4">
					<img class="grid-image" src="img/icon-3.png" alt="Icon-3">
					<h1 class="grid-title">Unlimited Colors</h1>
					<p class="grid-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rem cupiditate.</p>
				</div>


			</div>
		</div>
	</div> -->

  <!-- ==========================================================================================================
													  SLIDER
		 ========================================================================================================== -->

  <!-- <div class="fh5co-slider-outer wow fadeIn" data-wow-delay="0.36s">
		<h1>SIMPLE WIDGETS</h1>
		<small>Drag and Drop</small>
		<div class="container fh5co-slider-inner">

			<div class="owl-carousel owl-theme">

				<div class="item"><img src="img/smartphone-2.png" alt=""></div>
				<div class="item"><img src="img/smartphone-2.png" alt=""></div>
				<div class="item"><img src="img/iphone.png" alt=""></div>
				<div class="item"><img src="img/smartphone-2.png" alt=""></div>


			</div>

		</div>
	</div> -->

  <!-- ==========================================================================================================
													  FEATURES
		 ========================================================================================================== -->

  <!-- <div class="curved-bg-div wow animated fadeIn" data-wow-delay="0.15s"></div>
	<div id="fh5co-features" class="fh5co-features-outer">
		<div class="container">

			<div class="row fh5co-features-grid-columns">

				<div class="col-sm-6 in-order-1 wow animated fadeInLeft" data-wow-delay="0.22s">
					<div class="col-sm-image-container">
						<img class="img-float-left" src="img/smartphone-1.png" alt="smartphone-1">
						<span class="span-new">NEW</span>
						<span class="span-free">Free</span>
					</div>
				</div>

				<div class="col-sm-6 in-order-2 sm-6-content wow animated fadeInRight" data-wow-delay="0.22s">
					<h1>New Features</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam dolor iste beatae ad adipisci, fugiat dignissimos pariatur, dolore nemo suscipit cum nisi earum voluptates nulla! </p>
					<span class="circle circle-first"><i class="fab fa-instagram"></i></span>
					<span class="circle circle-middle"><i class="fab fa-facebook"></i></span>
					<span class="circle circle-last"><i class="fab fa-twitter"></i></span>
				</div>

				<div class="col-sm-6 in-order-3 sm-6-content wow animated fadeInLeft" data-wow-delay="0.22s">
					<h1>REAL-TIME STATISTICS</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam dolor iste beatae ad adipisci, fugiat dignissimos pariatur, dolore nemo suscipit cum nisi earum voluptates nulla! </p>
					<span class="circle circle-first"><i class="fas fa-star"></i></span>
					<span class="circle circle-middle"><i class="far fa-star"></i></span>
					<span class="circle circle-last"><i class="far fa-thumbs-up"></i></span>
				</div>

				<div class="col-sm-6 in-order-4 wow animated fadeInRight" data-wow-delay="0.22s">
					<img class="img-float-right" src="img/smartphone-2.png" alt="smartphone-2">
				</div>

				<div class="col-sm-6 in-order-5 wow animated fadeInLeft" data-wow-delay="0.22s">
					<div class="col-sm-image-container">
						<img class="img-float-left" src="img/smartphone-2.png" alt="smartphone-3">
						<span class="span-data">DATA</span>
						<span class="span-percent">100%</span>
					</div>
				</div>
				<div class="col-sm-6 in-order-6 sm-6-content wow animated fadeInRight" data-wow-delay="0.22s">
					<h1>POWERFUL BACKEND</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam dolor iste beatae ad adipisci, fugiat dignissimos pariatur, dolore nemo suscipit cum nisi earum voluptates nulla! </p>
					<span class="circle circle-first">95%</span>
					<span class="circle circle-middle"><i class="fas fa-forward"></i></span>
					<span class="circle circle-last"><i class="fab fa-github"></i></span>

				</div>




			</div>


		</div>
	</div> -->

  <!-- ==========================================================================================================
													  REVIEWS
		 ========================================================================================================== -->

  <!-- <div id="fh5co-reviews" class="fh5co-reviews-outer">
		<h1>What people are saying</h1>
		<small>Reviews</small>
		<div class="container fh5co-reviews-inner">
			<div class="row justify-content-center">
				<div class="col-sm-5 wow fadeIn animated" data-wow-delay="0.25s">
					<img class="float-left" src="img/quotes-1.jpg" alt="Quote 1">
					<p class="testimonial-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis similique quasi.</p>
					<small class="testimonial-author">John Doe</small>
					<img class="float-right" src="img/quotes-2.jpg" alt="Quote 2">
				</div>
				<div class="col-sm-5 testimonial-2 wow fadeIn animated" data-wow-delay="0.67s">
					<img class="float-left" src="img/quotes-1.jpg" alt="Quote 1">
					<p class="testimonial-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis similique quasi.</p>
					<small class="testimonial-author">Someone</small>
					<img class="float-right" src="img/quotes-2.jpg" alt="Quote 2">
				</div>
			</div>

		</div>
	</div> -->

  <!-- ==========================================================================================================
                                                 BOTTOM
    ========================================================================================================== -->

  <div id="fh5co-download" class="fh5co-bottom-outer">
    <div class="overlay">
      <div class="container fh5co-bottom-inner">
        <div class="row">
          <div class="col-sm-6">
            <!-- <h1>{{'General.DownloadTheApp' | translate}}</h1>
            <a class="wow fadeIn animated" data-wow-delay="0.25s" href="#">
              <img class="app-store-btn" src="./assets/img/app-store-icon.png" alt="App Store Icon" />
            </a>
            <a class="wow fadeIn animated" data-wow-delay="0.67s" href="#">
              <img class="google-play-btn" src="./assets/img/google-play-icon.png" alt="Google Play Icon" />
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ==========================================================================================================
                                               SECTION 7 - SUB FOOTER
    ========================================================================================================== -->

  <footer class="footer-outer">
    <div class="container footer-inner">
      <div class="footer-three-grid wow fadeIn animated" data-wow-delay="0.66s">
        <div class="column-1-3">
          <h1>{{'General.Title' | translate}}</h1>
        </div>
        <!-- <div class="column-2-3">
					<nav class="footer-nav">
						<ul>
							<a href="#" onclick="$('#fh5co-hero-wrapper').goTo();return false;"><li>Home</li></a>
							<a href="#" onclick="$('#fh5co-features').goTo();return false;"><li>Features</li></a>
							<a href="#" onclick="$('#fh5co-reviews').goTo();return false;"><li>Reviews</li></a>
							<a href="#" onclick="$('#fh5co-download').goTo();return false;"><li class="active">Download</li></a>
						</ul>
					</nav>
				</div> -->
        <!-- <div class="column-3-3">
					<div class="social-icons-footer">
						<a href="https://www.facebook.com/fh5co"><i class="fab fa-facebook-f"></i></a>
						<a href="https://freehtml5.co"><i class="fab fa-instagram"></i></a>
						<a href="https://www.twitter.com/fh5co"><i class="fab fa-twitter"></i></a>
					</div>
				</div> -->
      </div>

      <span class="border-bottom-footer"></span>

      <p class="copyright">
        &copy; {{'General.Copyright1' | translate}}
        <a href="https://agri.cbs-ds.com/" target="_blank">{{'General.Copyright2' | translate}}</a>
        .
      </p>
    </div>
  </footer>
</div>
