import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

import { ConfirmationComponent } from './component/confirmation/confirmation.component';
import { LoadingComponent } from './component/loading/loading.component';
import { SharedComponent } from './component/shared/shared.component';
import { SpinnerComponent } from './component/spinner/spinner.component';

const MATERIAL_MODULES = [MatFormFieldModule, MatInputModule, MatRippleModule, MatIconModule];
const SHARED_MODUlES = [FormsModule, ReactiveFormsModule, TranslateModule];

@NgModule({
  declarations: [SharedComponent, LoadingComponent, ConfirmationComponent, SpinnerComponent],
  imports: [MATERIAL_MODULES, SHARED_MODUlES, CommonModule],
  exports: [MATERIAL_MODULES, SHARED_MODUlES, SharedComponent, LoadingComponent, ConfirmationComponent, SpinnerComponent],
})
export class SharedModule {}
