import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from './../shared/shared.module';
import { RegisterValidateCodePageComponent } from './page/register-validate-code-page/register-validate-code-page.component';
import { ResetPasswordPageComponent } from './page/reset-password-page/reset-password-page.component';

@NgModule({
  declarations: [RegisterValidateCodePageComponent, ResetPasswordPageComponent],
  imports: [SharedModule, CommonModule],
})
export class LoginManagementModule {}
