<div class="container">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="flex column center" *ngIf="!isLoading">
    <section style="direction: ltr" class="c-container">
      <div *ngIf="result == Responses.SUCCESS" class="o-circle c-container__circle o-circle__sign--success">
        <div class="o-circle__sign"></div>
      </div>
      <div *ngIf="result != Responses.SUCCESS" class="o-circle c-container__circle o-circle__sign--failure">
        <div class="o-circle__sign"></div>
      </div>
    </section>
    <div style="height: 40px"></div>
    <div class="flex column center" *ngIf="result == Responses.SUCCESS">
      <span class="details">{{ 'Congratulations' | translate }}</span>
      <span class="details-sub">
        {{ 'General.WelcomeAfterRegistrationSuccess' | translate }}
      </span>
      <span class="details-sub">{{ 'General.WelcomeAfterRegistrationHint' | translate }}</span>
    </div>
    <div class="flex column center" *ngIf="result == Responses.VERIFICATION_CODE_EXPIRED">
      <span class="details">{{ 'Errors.CodeExpired' | translate }}</span>
      <span class="details-sub">{{ 'Errors.CodeExpiredHint' | translate }}</span>
    </div>
    <div class="flex column center" *ngIf="result == Responses.VERIFICATION_CODE_INVALID">
      <span class="details">{{ 'Errors.InvalidCode' | translate }}</span>
      <span class="details-sub">{{ 'Errors.InvalidCodeHint' | translate }}</span>
    </div>
    <div
      class="flex column center"
      *ngIf="result != Responses.SUCCESS && result != Responses.VERIFICATION_CODE_EXPIRED && result != Responses.VERIFICATION_CODE_INVALID"
    >
      <span class="details-sub">{{ 'Errors.ErrorHappened' | translate }}</span>
    </div>
  </div>
</div>
