export enum LanguageEnum {
  English = 'en',
  Arabic = 'ar',
}

export enum DeepLinksType {
  Register = 'Register',
  ForgetPassword = 'ForgetPassword',
}

export enum Responses {
  SUCCESS = 'SUCCESS',
  EMAIL_ALREADY_EXIST = 'EMAIL_ALREADY_EXIST',
  VERIFICATION_CODE_INVALID = 'VERIFICATION_CODE_INVALID',
  VERIFICATION_CODE_EXPIRED = 'VERIFICATION_CODE_EXPIRED',
  AUTHORIZATIONS_ERROR = 'AUTHORIZATIONS_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
}
