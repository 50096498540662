import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BackgroundScrollService } from '../../helper/background-scroll.service';
import { ConfirmationService } from '../../helper/confirmation.service';
import { Confirmation, ConfirmationType } from '../../model/confirmation';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {

  public confirmData: Confirmation = new Confirmation();
  public isVisible = false;
  public ConfirmationType = ConfirmationType;

  // private fields
  private dispose$ = new Subject();

  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private backgroundScrollService: BackgroundScrollService
  ) { }

  ngOnInit() {
    this.confirmationService.confirmationMessage$.pipe(takeUntil(this.dispose$)).subscribe(confirm => {
      if (confirm != null) {
        this.translate.get(['Confirm.Discard', 'Confirm.Confirm']).pipe(takeUntil(this.dispose$)).subscribe((translations: any) => {
          confirm.cancelActionText = translations['Confirm.Discard'];
          confirm.mainActionText = translations['Confirm.Confirm'];
          this.confirmData = confirm;
          this.isVisible = true;
          this.backgroundScrollService.setScrollState(false);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.dispose$.next(true);
  }

  onActionButtonClicked(value: boolean) {
    this.confirmationService.onConfirmMessageAction(value);
    this.isVisible = false;
    this.confirmData.message = '';
    this.backgroundScrollService.setScrollState(true);
  }

}
