import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackgroundScrollService {

  private renderer: Renderer2;

  constructor
    (
      private rendererFactory2: RendererFactory2
    ) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
  }

  public setScrollState(isScrollable: boolean) {
    if (isScrollable) {
      this.renderer.removeClass(document.body, 'no-scroll');
    } else {
      this.renderer.addClass(document.body, 'no-scroll');
    }
  }
}
