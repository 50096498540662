<div class="container flex column justify-center align-center">
  <div class="form-container box">
    <form class="form-group flex column justify-start align-start" [formGroup]="form">
      <div class="input flex column">
        <label>{{'General.Inputs.Password' | translate}}</label>
        <mat-form-field appearance="outline">
          <input
            name="password"
            matInput
            [type]="hidePassword ? 'password' : 'text'"
            [placeholder]="'Placeholder.EnterYourPassword' | translate"
            formControlName="password"
            autocomplete="password"
          />
          <mat-icon class="password-visibility" matSuffix (click)="hidePassword = !hidePassword">
            {{ hidePassword ? "visibility_off" : "visibility" }}
          </mat-icon>
          <mat-error *ngIf="form.invalid && form.get('password').hasError('required')">{{ "Errors.Required" | translate }}</mat-error>
          <mat-error *ngIf="form.invalid && form.get('password').hasError('invalidPassword')">{{ "Errors.InvalidPassword" | translate }}</mat-error>
          <mat-icon matPrefix>vpn_key</mat-icon>
        </mat-form-field>
      </div>

      <div class="input flex column">
        <label>{{'General.Inputs.PasswordConfirm' | translate}}</label>
        <mat-form-field appearance="outline">
          <input
            matInput
            name="confirmPassword"
            type="password"
            [placeholder]="'Placeholder.ConfirmYourPassword' | translate"
            formControlName="confirmPassword"
            autocomplete="confirmPassword"
          />
          <mat-error *ngIf="form.invalid && form.get('confirmPassword').hasError('required')">{{ "Errors.Required" | translate }}</mat-error>
          <mat-error *ngIf="form.invalid && form.get('confirmPassword').hasError('notMatch')">
            {{ "Errors.PasswordsNotMatched" | translate }}
          </mat-error>
          <mat-icon matPrefix>vpn_key</mat-icon>
        </mat-form-field>
      </div>

      <button [disabled]="form.invalid || isLoading" mat-raised-button color="color-on-error" (click)="resetPassword()" class="verify-button">
        {{'General.Buttons.ResetPassword' | translate}}
      </button>
    </form>
  </div>

  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="flex column center" *ngIf="!isLoading && result">
    <section style="direction: ltr" class="c-container">
      <div *ngIf="result == Responses.SUCCESS" class="o-circle c-container__circle o-circle__sign--success">
        <div class="o-circle__sign"></div>
      </div>
      <div *ngIf="result != Responses.SUCCESS" class="o-circle c-container__circle o-circle__sign--failure">
        <div class="o-circle__sign"></div>
      </div>
    </section>
    <div style="height: 40px"></div>
    <div class="flex column center" *ngIf="result == Responses.SUCCESS">
      <span class="details">{{ 'Congratulations' | translate }}</span>
      <span class="details-sub">{{ 'General.WelcomeAfterRegistrationSuccess' | translate }}</span>
      <span class="details-sub">{{ 'General.WelcomeAfterRegistrationHint' | translate }}</span>
    </div>
    <div class="flex column center" *ngIf="result == Responses.VERIFICATION_CODE_EXPIRED">
      <span class="details">{{ 'Errors.CodeExpired' | translate }}</span>
      <span class="details-sub">{{ 'Errors.CodeExpiredHint' | translate }}</span>
    </div>
    <div class="flex column center" *ngIf="result == Responses.VERIFICATION_CODE_INVALID">
      <span class="details">{{ 'Errors.InvalidCode' | translate }}</span>
      <span class="details-sub">{{ 'Errors.InvalidCodeHint' | translate }}</span>
    </div>
    <div
      class="flex column center"
      *ngIf="result != Responses.SUCCESS && result != Responses.VERIFICATION_CODE_EXPIRED && result != Responses.VERIFICATION_CODE_INVALID"
    >
      <span class="details-sub">{{ 'Errors.ErrorHappened' | translate }}</span>
    </div>
  </div>
</div>
