import { ResetPasswordPageComponent } from './ui/login-management/page/reset-password-page/reset-password-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RegisterValidateCodePageComponent } from './ui/login-management/page/register-validate-code-page/register-validate-code-page.component';
import { NotFoundPageComponent } from './ui/static/page/not-found-page/not-found-page.component';
import { WelcomePageComponent } from './ui/static/page/welcome-page/welcome-page.component';
import { WrapperComponent } from './wrapper/wrapper.component';

const routes: Routes = [
  {
    path: 'register-validate-code/:code',
    component: RegisterValidateCodePageComponent,
  },
  {
    path: 'reset-password/:code',
    component: ResetPasswordPageComponent,
  },
  // { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot([
    {
        path: '',
        component: WelcomePageComponent,
    },
    {
        path: 'page',
        component: WrapperComponent,
        children: routes,
    },
    {
        path: '404',
        component: NotFoundPageComponent,
    },
    { path: '**', redirectTo: '/404' },
], { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
