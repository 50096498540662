import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LoadingService } from './../../helper/loading.service';
import { LoadingStatus } from './../../model/shared.enums';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @ViewChild('loadingOverlayPage', { static: true }) loadingOverlayPage: ElementRef;
  isHidden$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private renderer: Renderer2,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.loadingService.loadingStatus$.subscribe((status: LoadingStatus) => {
      switch (status) {
        case LoadingStatus.Hidden:
          this.hide();
          break;

        case LoadingStatus.Visible:
          this.isHidden$.next(false);
          break;

        case LoadingStatus.VisibleTransparent:
          this.showTransparent();
          break;
      }
    });
  }

  showTransparent() {
    this.renderer.setStyle(
      this.loadingOverlayPage.nativeElement,
      'background',
      '#00000061'
    );
    this.isHidden$.next(false);
  }

  hide() {
    this.isHidden$.next(true);
    this.renderer.addClass(this.loadingOverlayPage.nativeElement, 'hidden');
  }
}
